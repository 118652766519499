import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getBlogPosts } from './utils/BlogUtils';
import { BlogPost } from './models/BlogPost';
import LoadingSpinner from './components/LoadingSpinner';

const Blog: React.FC = () => {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const posts = await getBlogPosts();
        setBlogPosts(posts);
      } catch (err) {
        setError('Failed to fetch blog posts');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) return 
    <div className="new-home-container">
        <div className="new-home-content">
            <div className="new-home-inner">
                <LoadingSpinner />;

            </div>
        </div>
    </div>

  if (error) return 
    <div className="new-home-container">
    <div className="new-home-content">
            <div className="new-home-inner">
                <div className="error-message">Error: {error}</div>;
            </div>
        </div>
    </div>

  return (
    <>
      <Helmet>
        <title>Blog | Social Work Test Prep Academy</title>
        <meta name="description" content="Read our latest blog posts on ASWB LCSW exam preparation tips, social work insights, and more." />
      </Helmet>
      <div className="new-home-container">
        <div className="new-home-content">
        <h1 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">Tips and Tricks to Passing the ASWB Ease</h1>
          {/* <div className="new-home-inner"> */}
            {/* <h1 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">Tips and Tricks to Passing the ASWB Ease</h1> */}
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
              {blogPosts.map((post) => (
                <Link key={post.id} to={`/blog/${post.slug}`} className="block">
                  <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden feature-card">
                    <div className="p-6">
                      <h2 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">{post.title}</h2>
                      <p className="text-gray-600 dark:text-gray-400 mb-2">{post.excerpt}</p>
                      <p className="text-sm text-gray-500 dark:text-gray-500">{new Date(post.publishDate).toLocaleDateString()}</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Blog;
