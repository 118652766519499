import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasSavedQuizProgress, initializeNewQuiz, loadTestResults, continueExistingQuiz, loadQuizProgress } from './utils/quizUtils';
import { Question } from './models/Quiz'
import { TestResult, WrongAnswer, User, UserProfile } from './models/User'
import { PASSING_PERCENTAGE, DATE_FORMAT_OPTIONS, NO_PREVIOUS_QUIZ_MESSAGE } from './constants';
import { ScoreGraph } from './components/ScoreGraph';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingSpinner from './components/LoadingSpinner';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from './utils/Analytics';
import { generateMockQuizData } from './utils/MockQuiz'
import { updateUserProfile, loadUserProfile } from './utils/FirebaseUtils'

const UserPage: React.FC = () => {
  const [testScores, setTestScores] = useState<TestResult[]>([]);
  const [wrongAnswers, setWrongAnswers] = useState<Array<WrongAnswer>>([]);
  const navigate = useNavigate();
  const [hasSavedProgress, setHasSavedProgress] = useState(false);
  const [examDate, setExamDate] = useState<string | undefined>(undefined);
  const [tempExamDate, setTempExamDate] = useState('');
  const [isEditingDate, setIsEditingDate] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedQuizDate, setSelectedQuizDate] = useState<string | 'all'>('all');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [continueQuizError, setContinueQuizError] = useState<string | null>(null);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [testResultsLoaded, setTestResultsLoaded] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  const quizDates = useMemo(() => {
    return Array.from(new Set(wrongAnswers.map(instance => instance.date)))
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
  }, [wrongAnswers]);

  const filteredWrongAnswers = useMemo(() => {
    return wrongAnswers.filter((instance) => {
      const questionText = instance.question?.question_text?.toLowerCase() ?? '';
      const explanation = instance.explanation?.toLowerCase() ?? '';
      const searchTermLower = searchTerm.toLowerCase();

      const matchesSearch = questionText.includes(searchTermLower) ||
                            explanation.includes(searchTermLower);
      const matchesQuiz = selectedQuizDate === 'all' || instance.date === selectedQuizDate;
      return matchesSearch && matchesQuiz;
    });
  }, [wrongAnswers, searchTerm, selectedQuizDate]);

  useEffect(() => {
    const loadUserData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const storedTestResults = await loadTestResults();
        const profile = await loadUserProfile();
        setUserProfile(profile);
        console.log("Loaded test results:", storedTestResults);
        
        if (storedTestResults) {
          // Ensure storedTestResults is always an array
          const testResultsArray = Array.isArray(storedTestResults) ? storedTestResults : [storedTestResults];
          setTestScores(testResultsArray.map((result, index) => ({
            id: index + 1,
            ...result
          })));
          
          // Collect all wrong answers from all test results
          const allWrongAnswers = testResultsArray.flatMap(result => result.wrongAnswers || []);
          setWrongAnswers(allWrongAnswers);
        } else {
          console.log("No test results found");
          setTestScores([]);
          setWrongAnswers([]);
        }

        // const { testScores: mockTestScores, wrongAnswers: mockWrongAnswers } = generateMockQuizData(6);
        
        // if (mockTestScores.length > 0) {
        //   setTestScores(mockTestScores.map((result, index) => ({
        //     id: index + 1,
        //     ...result
        //   })));
        //   setWrongAnswers(mockWrongAnswers);
        // }

        const { inProgress } = await hasSavedQuizProgress();
        setHasSavedProgress(inProgress);

        // Use exam date from profile if available and valid
        if (profile && profile.examDate && isValidDate(profile.examDate)) {
          setExamDate(profile.examDate);
        } else {
          // Fallback to localStorage or API as before
          const savedExamDate = localStorage.getItem('examDate');
          if (savedExamDate && isValidDate(savedExamDate)) {
            setExamDate(savedExamDate);
          }
        }
      } catch (err) {
        console.error("Error loading user data:", err);
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUserData();

    // Add event listener for quiz completion
    const handleQuizCompleted = () => {
      setHasSavedProgress(false);
    };

    window.addEventListener('quizCompleted', handleQuizCompleted);

    return () => {
      window.removeEventListener('quizCompleted', handleQuizCompleted);
    };
  }, []);

  const isValidDate = (dateString: string): boolean => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <LoadingSpinner />
        <p>Loading your profile...</p>
        {profileLoaded && <p>Profile information loaded</p>}
        {testResultsLoaded && <p>Test results loaded</p>}
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const formatDate = (dateString: string | undefined): string => {
    if (!dateString) {
      const mostRecentTest = testScores[testScores.length - 1];
      return mostRecentTest ? new Date(mostRecentTest.date).toLocaleDateString('en-US', DATE_FORMAT_OPTIONS) : 'Invalid Date';
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.warn(`Invalid date string: ${dateString}`);
      return 'Invalid Date';
    }
    return date.toLocaleDateString('en-US', DATE_FORMAT_OPTIONS);
  };

  const getQuizNumber = (instanceDate: string | undefined): number => {
    if (!instanceDate || testScores.length === 0) return 1;
    const quizIndex = testScores.findIndex(test => new Date(test.date) >= new Date(instanceDate));
    return quizIndex === -1 ? testScores.length : quizIndex + 1;
  };

  const handleContinueQuiz = async () => {
    try {
      const existingQuizId = await continueExistingQuiz();
      if (existingQuizId) {
        navigate(`/quiz/${existingQuizId}`);
        logAnalyticsEvent(ANALYTICS_EVENTS.CONTINUE_SAVED_QUIZ);
      } else {
        setContinueQuizError("No existing quiz found to continue.");
      }
    } catch (error) {
      console.error('Error continuing quiz:', error);
      setContinueQuizError("Failed to continue quiz. Please try again.");
    }
  };
  
  const handleRetakeQuiz = async () => {
    try {
      const newQuizId = await initializeNewQuiz();
      if (newQuizId) {
        const newQuizProgress = await loadQuizProgress(newQuizId.quizId);
        if (newQuizProgress) {
          navigate(`/quiz/${newQuizId}`);
          logAnalyticsEvent(ANALYTICS_EVENTS.RETAKE_QUIZ);
        } else {
          setContinueQuizError("Failed to load new quiz. Please try again.");
        }
      } else {
        setContinueQuizError("Failed to start new quiz. Please try again.");
      }
    } catch (error) {
      console.error('Error initializing new quiz:', error);
      setContinueQuizError("Failed to start new quiz. Please try again.");
    }
  };

  const handleSetExamDate = async () => {
    if (tempExamDate && isValidDate(tempExamDate)) {
      setExamDate(tempExamDate);
      localStorage.setItem('examDate', tempExamDate);
      // Update user profile if available
      if (userProfile) {
        try {
          const updatedProfile = { ...userProfile, examDate: tempExamDate };
          await updateUserProfile(updatedProfile);
          setUserProfile(updatedProfile);
        } catch (error) {
          console.error('Failed to update user profile:', error);
          // Handle error (e.g., show an error message to the user)
        }
      }
      setIsEditingDate(false);
    }
  };

  const handleEditExamDate = () => {
    setIsEditingDate(true);
    setTempExamDate(examDate || '');
  };

  const handleCancelEdit = () => {
    setIsEditingDate(false);
    setTempExamDate('');
  };

  const hasQuestionsToReview = Object.keys(wrongAnswers).length > 0;

  return (
    <ErrorBoundary>
      <div className="new-home-container">
        <div className="new-home-content">
          {/* Test Scores section */}
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Test Scores</h2>
            
            {testScores.length > 0 && (
              <div className="mb-8">
                {examDate && !isEditingDate ? (
                  <div className="mb-4">
                    <ScoreGraph testScores={testScores} examDate={examDate} />
                    <div className="mt-2 flex justify-end">
                      <button
                        onClick={handleEditExamDate}
                        className="text-blue-500 hover:text-blue-700 font-semibold"
                      >
                        Change Exam Date
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="text-gray-600 dark:text-gray-400 mb-2">
                      {isEditingDate ? 'Edit your exam date:' : 'Set your exam date to see the score graph:'}
                    </p>
                    <div className="flex items-center">
                      <input
                        type="date"
                        value={tempExamDate}
                        onChange={(e) => setTempExamDate(e.target.value)}
                        className="border rounded-l px-2 py-1 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600"
                      />
                      <button
                        onClick={handleSetExamDate}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-r"
                      >
                        {isEditingDate ? 'Update' : 'Set'} Date
                      </button>
                    </div>
                    {isEditingDate && (
                      <button
                        onClick={handleCancelEdit}
                        className="mt-2 text-gray-500 hover:text-gray-700"
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
            
            {/* Existing table */}
            <div className="overflow-x-auto mt-4">
              <table className="w-full bg-white dark:bg-gray-700 shadow-md rounded-lg overflow-hidden">
                <thead className="bg-gray-200 dark:bg-gray-600">
                  <tr>
                    <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-200">Quiz</th>
                    <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-200">Date</th>
                    <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-200">Score</th>
                    <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-200">Total Questions</th>
                    <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-200">Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {testScores.map((test, index) => (
                    <tr key={test.id} className="border-b dark:border-gray-600">
                      <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{index + 1}</td>
                      <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{formatDate(test.date)}</td>
                      <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{test.score}</td>
                      <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{test.totalQuestions}</td>
                      <td className="px-4 py-2">
                        <span className={`font-semibold ${test.percentage >= PASSING_PERCENTAGE ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                          {test.percentage.toFixed(2)}%
                        </span>
                        <span className="ml-2">{test.percentage >= PASSING_PERCENTAGE ? '✅' : '❌'}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          {/* Questions to Review section */}
          {wrongAnswers.length > 0 && (
            <section>
              <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Questions to Review</h2>
              
              {/* Search and Filter controls */}
              <div className="mb-4 flex flex-wrap gap-4">
                <input
                  type="text"
                  placeholder="Search questions..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="border rounded px-2 py-1 flex-grow dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600"
                />
                <select
                  value={selectedQuizDate}
                  onChange={(e) => setSelectedQuizDate(e.target.value)}
                  className="border rounded px-2 py-1 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600"
                >
                  <option value="all">All Quizzes</option>
                  {quizDates.map((date) => (
                    <option key={date} value={date}>
                      {new Date(date).toLocaleDateString('en-US', DATE_FORMAT_OPTIONS)}
                    </option>
                  ))}
                </select>
              </div>

              {/* Filtered questions */}
              <div className="space-y-4">
                {filteredWrongAnswers.map((instance, index) => (
                  <div key={`${instance.question.id}-${index}`} className="bg-white dark:bg-gray-700 shadow-md rounded-lg p-4">
                    <h4 className="font-semibold mb-2 text-gray-800 dark:text-gray-200">Question: {instance.question.question_text}</h4>
                    <p className="text-gray-700 dark:text-gray-300"><strong>Your Explanation:</strong> {instance.explanation}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                      Date: {new Date(instance.date).toLocaleDateString('en-US', DATE_FORMAT_OPTIONS)}
                    </p>
                  </div>
                ))}
              </div>
            </section>
          )}

          {/* Quiz Actions section */}
          <section className="mt-8 space-y-4">
            {hasSavedProgress && (
              <button 
                onClick={handleContinueQuiz}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full dark:bg-green-600 dark:hover:bg-green-800"
              >
                Continue Saved Quiz
              </button>
            )}
            <button 
              onClick={handleRetakeQuiz}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full dark:bg-blue-600 dark:hover:bg-blue-800"
            >
              Retake Last Quiz (Randomized)
            </button>
            {continueQuizError && (
              <p className="text-red-500 mt-2">
                Error: {continueQuizError}
              </p>
            )}
          </section>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default UserPage;