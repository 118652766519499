import React from 'react';
import { QuizProgress } from './models/User';
import AnswerOptions from './components/AnswerOptions';

interface FreeQuizProps {
  quizProgress: QuizProgress;
  handleAnswer: (answer: string) => void;
  nextQuestion: () => void;
  showHint: boolean;
  setShowHint: (show: boolean) => void;
  selectedAnswer: string | null;
  showResult: boolean;
  currentExplanation: string;
  handleExplanationChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSaveAndContinue: () => void;
}

const FreeQuiz: React.FC<FreeQuizProps> = ({
  quizProgress,
  handleAnswer,
  nextQuestion,
  showHint,
  setShowHint,
  selectedAnswer,
  showResult,
  currentExplanation,
  handleExplanationChange,
  handleSaveAndContinue
}) => {
  const currentQuestion = quizProgress.questions[quizProgress.currentQuestionIndex];

  return (
    <div className="free-quiz space-y-6">
      <h2 className="text-2xl font-bold text-white">Free Quiz</h2>
      <p className="question-text text-lg text-white">{currentQuestion.question_text}</p>
      
      {currentQuestion.hint && currentQuestion.hint !== "" && (
        <div className="mt-4">
          <button 
            className="hint-button bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600" 
            onClick={() => setShowHint(!showHint)}
          >
            {showHint ? 'Hide Hint' : 'Show Hint'}
          </button>
          {showHint && (
            <p className="hint-text mt-2 text-yellow-300">{currentQuestion.hint}</p>
          )}
        </div>
      )}

      <AnswerOptions
        currentQuestion={currentQuestion}
        selectedAnswer={selectedAnswer}
        showResult={showResult}
        handleAnswer={handleAnswer}
        isCorrect={(option) => option === currentQuestion.correct_answer}
      />

      {showResult && (
        <div className="result-container mt-6 p-4 bg-gray-700 rounded-lg">
          {selectedAnswer !== currentQuestion.correct_answer ? (
            <>
              <div className="explanation-section space-y-4">
                <p className="text-red-500 font-semibold">Incorrect. The correct answer is: {currentQuestion.correct_answer}</p>
                <p className="text-white"><strong>Explanation:</strong> {currentQuestion.explanation}</p>
                <p className="font-bold text-blue-400">Please explain this concept in your own words:</p>
                <textarea
                  value={currentExplanation}
                  onChange={handleExplanationChange}
                  placeholder="Enter your notes here..."
                  rows={4}
                  className="w-full p-2 bg-gray-600 text-white rounded"
                />
              </div>
              <div className="button-container mt-4">
                <button 
                  className="save-button bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600" 
                  onClick={handleSaveAndContinue}
                >
                  Save and Continue
                </button>
              </div>
            </>
          ) : (
            <button 
              onClick={nextQuestion}
              className="next-question-button bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
            >
              Next Question
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default FreeQuiz;