import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { getBlogPostBySlug } from './utils/BlogUtils';
import { BlogPost as BlogPostType } from './models/BlogPost';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaArrowLeft } from 'react-icons/fa';
import LoadingSpinner from './components/LoadingSpinner';

const BlogPost: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPostType | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      if (slug) {
        try {
          const fetchedPost = await getBlogPostBySlug(slug);
          console.log('Fetched post:', fetchedPost);
          console.log('Post content type:', typeof fetchedPost.content);
          setPost(fetchedPost);
        } catch (err) {
          setError('Failed to fetch blog post');
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchBlogPost();
  }, [slug]);

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="error-message text-red-500 dark:text-red-400">Error: {error}</div>;
  if (!post) return <div className="not-found text-gray-600 dark:text-gray-400">Blog post not found</div>;

  const shareUrl = `${window.location.origin}/blog/${post.slug}`;
  console.log('Raw Markdown content:', post.content);
  const cleanMarkdown = (markdown: string) => {
    return markdown
      .replace(/\r\n/g, '\n') // Normalize line endings
      .split('\n')
      .map(line => line.trim()) // Remove leading/trailing whitespace
      .join('\n')
      .replace(/\n{3,}/g, '\n\n') // Replace multiple blank lines with double line breaks
      .replace(/^(#+)/gm, '\n$1 ') // Ensure headers have a newline before them and a space after #
      .trim(); // Remove any leading/trailing whitespace from the entire string
  };

  return (
    <>
      <Helmet>
        <title>{post.title} | Social Work Test Prep Academy Blog</title>
        <meta name="description" content={post.excerpt} />
      </Helmet>
      <div className="new-home-container">
        <div className="new-home-content">
        <Link to="/blog" className="inline-flex items-center text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 mb-6">
              <FaArrowLeft className="mr-2" />
              Back to Blog
            </Link>
          <div className="new-home-inner">
            <article className="max-w-3xl mx-auto">
              <h1 className="text-4xl font-bold mb-4 text-gray-800 dark:text-gray-200">{post.title}</h1>
              {post.author && (
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  By {post.author} | {new Date(post.publishDate).toLocaleDateString()}
                </p>
              )}
              {!post.author && (
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  {new Date(post.publishDate).toLocaleDateString()}
                </p>
              )}
              <div className="prose dark:prose-dark max-w-none text-gray-800 dark:text-gray-200 markdown-content">
              <div 
        className="blog-content"
        dangerouslySetInnerHTML={{ __html: post.content }} 
      />
              </div>
              
              <div className="mt-8">
                <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Share this post</h2>
                <div className="flex space-x-4">
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300">
                    <FaFacebookF size={24} />
                  </a>
                  <a href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(post.title)}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600 dark:text-blue-300 dark:hover:text-blue-200">
                    <FaTwitter size={24} />
                  </a>
                  <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${encodeURIComponent(post.title)}`} target="_blank" rel="noopener noreferrer" className="text-blue-700 hover:text-blue-900 dark:text-blue-500 dark:hover:text-blue-400">
                    <FaLinkedinIn size={24} />
                  </a>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;