import { BlogPost } from '../models/BlogPost';
import { collection, query, where, orderBy, getDocs, limit, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';  // Import the db instance from your firebase.ts file
import { DocumentData } from 'firebase/firestore';

// Add this type guard function
function isBlogPost(data: DocumentData): data is BlogPost {
  return (
    typeof data.title === 'string' &&
    typeof data.slug === 'string' &&
    typeof data.content === 'string' &&
    typeof data.publishDate === 'string'
    // Add any other necessary checks based on your BlogPost interface
  );
}

const CACHE_KEY = 'blogPosts';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const defaultImages = [
  "/images/studyanywhere.png",
  "/images/learn.png",
  "/images/stayontrack.png",
  "/images/practice.png"
];

const getRandomImage = () => defaultImages[Math.floor(Math.random() * defaultImages.length)];

export const getBlogPosts = async (): Promise<BlogPost[]> => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  const cachedTime = localStorage.getItem(`${CACHE_KEY}_time`);

  if (cachedData && cachedTime) {
    const parsedData = JSON.parse(cachedData);
    const timeSinceCache = Date.now() - parseInt(cachedTime, 10);

    if (timeSinceCache < CACHE_DURATION) {
      console.log('Returning cached blog posts');
      return parsedData;
    }
  }

  try {
    console.log('Fetching fresh blog posts from Firestore');
    const blogPostsRef = collection(db, 'blogPosts');
    const q = query(blogPostsRef, orderBy('publishDate', 'desc'));
    const querySnapshot = await getDocs(q);

    const posts = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        title: data.title,
        author: data.author,
        content: data.content,
        excerpt: data.excerpt,
        slug: data.slug,
        publishDate: data.publishDate,
        published: data.published,
        imageUrl: data.imageUrl || getRandomImage()
      } as BlogPost;
    });

    localStorage.setItem(CACHE_KEY, JSON.stringify(posts));
    localStorage.setItem(`${CACHE_KEY}_time`, Date.now().toString());

    return posts;
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    throw new Error('Failed to fetch blog posts');
  }
};

export const getBlogPostBySlug = async (slug: string): Promise<BlogPost | null> => {
  try {
    console.log(`Fetching blog post with slug: ${slug}`);
    const blogPostsRef = collection(db, 'blogPosts');
    const q = query(blogPostsRef, where('slug', '==', slug), orderBy('publishDate', 'desc'), limit(1));
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      console.log('No matching blog post found');
      return null;
    }
    
    const postDoc = querySnapshot.docs[0];
    const postData = postDoc.data();

    return {
      id: postDoc.id,
      title: postData.title,
      content: postData.content,
      excerpt: postData.excerpt,
      slug: postData.slug,
      publishDate: postData.publishDate,
      imageUrl: postData.imageUrl || getRandomImage(),
      author: postData.author
    } as BlogPost;
  } catch (error) {
    console.error('Error fetching blog post:', error);
    throw new Error('Failed to fetch blog post');
  }
};