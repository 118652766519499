import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasSavedQuizProgress, hasCompletedTests, initializeNewQuiz, QuizInitResult, continueExistingQuiz, loadQuizProgress, initializeQuizForUser } from './utils/quizUtils';
import { auth } from './firebase';
import { sendSignInLinkToEmail, onAuthStateChanged, User } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { checkSubscriptionStatus, setupUserStatusListener } from './utils/FirebaseUtils'
import './styles/Home.css';
import { Helmet } from 'react-helmet';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingSpinner from './components/LoadingSpinner';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from './utils/Analytics';

const structuredData = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "name": "Social Work Test Prep Academy",
  "url": "https://www.socialworktestprepacademy.com",
  "description": "Prepare for your ASWB LCSW Exam with our comprehensive practice tests and study materials.",
};

const Home = () => {
  const [email, setEmail] = useState('');
  const [hasQuizInProgress, setHasQuizInProgress] = useState(false);
  const [hasUserProfile, setHasUserProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [showQuizInfo, setShowQuizInfo] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [signUpStatus, setSignUpStatus] = useState<'idle' | 'sending' | 'sent' | 'error'>('idle');
  const [user, setUser] = useState<User | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [quizInitError, setQuizInitError] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [quizProgressLoaded, setQuizProgressLoaded] = useState(false);
  const [continueQuizError, setContinueQuizError] = useState<string | null>(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscribedTestInProgress, setSubscribedTestInProgress] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Auth state changed. User:", user);
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      console.log("Auth state changed. Current user:", currentUser);
      setUser(currentUser);
      if (!currentUser) {
        console.log("No user, setting isLoading to false");
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    console.log("User effect triggered. User:", user);
    let isMounted = true;
    let unsubscribe: (() => void) | undefined;

    const setupUserStatus = async () => {
      if (user && isMounted) {
        console.log("Setting up user status for:", user.uid);
        setIsLoading(true);
        try {
          const subscriptionStatus = await checkSubscriptionStatus();
          console.log("Subscription status:", subscriptionStatus);
          if (isMounted) {
            setIsSubscribed(subscriptionStatus);
            setIsInitialized(true);
          }

          unsubscribe = setupUserStatusListener(user.uid, (subscribed, testInProgress) => {
            console.log('Listener update - Subscribed:', subscribed, 'Test in progress:', testInProgress);
            if (isMounted) {
              setIsSubscribed(subscribed);
              setSubscribedTestInProgress(testInProgress);
            }
          });
        } catch (error) {
          console.error('Error setting up user status:', error);
        } finally {
          console.log("Finished setting up user status, setting isLoading to false");
          if (isMounted) {
            setIsLoading(false);
          }
        }
      } else {
        console.log("No user, not setting up status");
        if (isMounted) {
          setIsInitialized(true);
          setIsLoading(false);
        }
      }
    };

    setupUserStatus();

    return () => {
      isMounted = false;
      if (unsubscribe) {
        console.log("Unsubscribing from user status listener");
        unsubscribe();
      }
    };
  }, [user]);

  useEffect(() => {
    logAnalyticsEvent('home_page_viewed');
  }, []);

  useEffect(() => {
    console.log("isLoading changed:", isLoading);
  }, [isLoading]);

  const actionCodeSettings = {
    url: `${window.location.origin}/finish-signin`,
    handleCodeInApp: true,
  };

  const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate('/finish-signin', { state: { email } });
  };

  const handleStartQuiz = async () => {
    setShowQuizInfo(true);
  };

  const handleStartNewQuiz = async () => {
    setIsLoading(true);
    try {
      const quizId = await initializeQuizForUser(isSubscribed);
      navigate(`/quiz/${quizId}`);
    } catch (error) {
      console.error('Error initializing new quiz:', error);
      setQuizInitError('Failed to start new quiz. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoToProfile = () => {
    navigate('/profile');
  };

  useEffect(() => {
    if (userDataLoaded && quizProgressLoaded) {
      setIsLoading(false);
    }
  }, [userDataLoaded, quizProgressLoaded]);

  const handleContinueQuiz = async () => {
    try {
      const existingQuizId = await continueExistingQuiz();
      if (existingQuizId) {
        const savedProgress = await loadQuizProgress(existingQuizId, isSubscribed);
        if (savedProgress) {
          navigate(`/quiz/${existingQuizId}`);
        } else {
          setContinueQuizError("No existing quiz progress found.");
        }
      } else {
        setContinueQuizError("No existing quiz found to continue.");
      }
    } catch (error) {
      console.error('Error continuing quiz:', error);
      setContinueQuizError("Failed to continue quiz. Please try again.");
    }
  };

  const handleSubscribedTest = () => {
    console.log(subscribedTestInProgress)
    if (subscribedTestInProgress) {
      navigate(`/quiz/${subscribedTestInProgress}`);
    } else {
      setShowQuizInfo(true);
    }
    // setShowQuizInfo(true);

  };

  if (isLoading) {
    <div className="new-home-container">
      <div className="new-home-content">
        <div className="new-home-inner">
          return <LoadingSpinner />;
        </div>
      </div>
    </div>
  }

  if (!user) {
    console.log("Rendering non-authenticated view");
    return (
      <div className="new-home-container">
        <div className="new-home-content">
          <div className="new-home-inner">
            <div className="md:flex">
              <div className="md:w-1/2 hidden md:block">
                <div className="h-96 md:h-full w-full hero-image"></div>
              </div>
              <div className="w-full md:w-1/2 p-4 md:p-8">
                <h1 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-2">ASWB LCSW Exam Prep: Your Path to Licensure Made Easy</h1>
                <p className="text-base text-gray-600 dark:text-gray-300 mb-4">Master the Exam with Our Expert-Curated 30-Question Quiz</p>
                <div className="max-w-md mx-auto">
                  <form onSubmit={handleEmailSubmit} className="flex flex-col sm:flex-row">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                      className="flex-grow px-4 py-2 mb-2 sm:mb-0 sm:mr-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                    />
                    <button
                      type="submit"
                      className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Start Free Quiz
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="mt-12">
              <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Everything you need to pass your ASWB LCSW Exam</h2>
              <p className="text-base text-gray-600 dark:text-gray-300 mb-4">
                The Social Work Test Prep Academy offers a prep course for ASWB social work licensing exams. We designed this platform of practice exams to help you learn and retain the information needed to pass your exam. Our exams are created by a subject matter expert with over 20 years of experience from a leading social work university. You will also have access to our dashboard, which tracks your progress and helps you stay on track with your study goals.
              </p>

              <p className="text-base text-gray-600 dark:text-gray-300 mb-8">
                Our exams are curated by a LICSW, UofM graduate, and dedicated therapist who passed the exam in 2014 after 20 years of work in various clinical settings. The exam questions are based on clinical scenarios, requiring application of reasoning, diagnosis, and use of qualifiers. They align with the 2024 ASWB KSA content guidelines and the code of ethics.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {[
                  { title: "Study anywhere, anytime", description: "Our courses are designed to give you the flexibility to study whenever you have time, while simulating the real exam experience.", image: "/images/studyanywhere.png" },
                  { title: "Learn from top instructors", description: "Our courses are curated by LICSW UofM graduates and dedicated therapists who have passed the exam and are experts in their field", image: "/images/learn.png" },
                  { title: "Stay on track with personalized progress tracking", description: "Our dashboard tracks your quiz results and helps you learn from your mistakes, while giving you the tools to stay on track with your study goals", image: "/images/stayontrack.png" },
                  { title: "Practice with real exam questions", description: "Our course includes full-length practice exams, so you can practice with real exam questions and get comfortable with the format", image: "/images/practice.png" },
                ].map((feature, index) => (
                  <div key={index} className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden feature-card">
                    <div className={`h-48 w-full ${
                      feature.title === "Study anywhere, anytime" ? "study-anywhere-image" :
                      feature.title === "Learn from top instructors" ? "learn-image" :
                      feature.title === "Stay on track with personalized progress tracking" ? "stay-on-track-image" :
                      "practice-image"
                    }`}></div>
                    <div className="p-6">
                      <h3 className="font-semibold text-lg mb-2 dark:text-white">{feature.title}</h3>
                      <p className="text-gray-600 dark:text-gray-300">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-16 text-center">
              <h2 className="text-5xl font-bold text-gray-900 dark:text-white mb-4">Ready to take the next step?</h2>
              <p className="text-gray-600 dark:text-gray-300 mb-8">Sign up for a free 30 question practice test to get started on your journey to licensure</p>
              <div className="max-w-md mx-auto">
                <form onSubmit={handleEmailSubmit} className="flex flex-col sm:flex-row">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                    className="flex-grow px-4 py-2 mb-2 sm:mb-0 sm:mr-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                  />
                  <button
                    type="submit"
                    className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Start Free Quiz
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showQuizInfo) {
    return (
      <div className="new-home-container">
        <div className="new-home-content">
          <div className="new-home-inner quiz-info-container">
            <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-center">Quiz Info</h1>
            <ul className="list-disc pl-5 py-3 space-y-2">
              <li>Each question is timed based on how long an exam would take.</li>
              <li>Questions are randomized for each attempt.</li>
              <li>When you get an answer wrong, you can explain your reasoning for later review.</li>
              <li>Hints are available for some questions to guide you towards the right answer.</li>
              <li>You can come back to the test at any time to continue.</li>
              <li>Your results will be saved and can be compared to older tests to see your progress.</li>
              {isSubscribed && <li>As a subscribed user, access to a full 170-question test.</li>}
            </ul>
            <button 
              onClick={handleStartNewQuiz}
              className="cta-button w-full text-center mt-4"
            >
              Start {isSubscribed ? "Full" : "Free"} Quiz
            </button>
            {quizInitError && (
              <p className="text-red-500 mt-2">
                Error: {quizInitError}. Please try again or contact support.
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }

  console.log("Rendering authenticated view");
  return (
    <ErrorBoundary>
      <Helmet>
        <title>ASWB LCSW Exam Prep | Social Work Test Prep Academy</title>
        <meta name="description" content="Prepare for your ASWB LCSW Exam with our comprehensive practice tests and study materials. Start your journey to licensure today!" />
        <meta property="og:title" content="ASWB LCSW Exam Prep | Social Work Test Prep Academy" />
        <meta property="og:description" content="Master the ASWB LCSW Exam with our expert-curated 30-question quiz and comprehensive study materials." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="new-home-container">
        <div className="new-home-content">
          <div className="new-home-inner welcome-back-container">
            <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-center">Welcome Back!</h1>
            <p className="text-base sm:text-lg mb-6 opacity-80 text-center">
              {isSubscribed 
                ? "Ready to continue your ASWB LCSW Exam preparation with our comprehensive 170-question exam?"
                : "Ready to continue your ASWB LCSW Exam preparation?"}
            </p>
            <div className="space-y-4">
              {isSubscribed ? (
                <button 
                  onClick={handleSubscribedTest}
                  className="cta-button w-full text-center mt-4"
                >
                  {subscribedTestInProgress ? "Continue 170-Question Exam" : "Start 170-Question Exam"}
                </button>
              ) : (
                <>
                  {hasQuizInProgress && (
                    <button 
                      onClick={handleContinueQuiz} 
                      className="cta-button w-full text-center mt-4"
                    >
                      Continue Quiz
                    </button>
                  )}
                  <button
                    onClick={handleStartQuiz}
                    className="cta-button w-full text-center"
                  >
                    Start New Quiz
                  </button>
                </>
              )}
              {hasUserProfile && (
                <button
                  onClick={handleGoToProfile}
                  className="secondary-button w-full text-center"
                >
                  View Profile
                </button>
              )}
              {continueQuizError && (
                <p className="text-red-500 mt-2">
                  Error: {continueQuizError}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Home;