import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QuizContainer from './components/QuizContainer';
import FreeQuiz from './FreeQuiz';
import SubscriberQuiz from './SubscriberQuiz';
import { checkSubscriptionStatus, initializeQuizForUser } from './utils/quizUtils';

const QuizApp: React.FC = () => {
  const { quizId: paramQuizId } = useParams();
  const [quizId, setQuizId] = useState<number | null>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initQuiz = async () => {
      setIsLoading(true);
      try {
        const subscriptionStatus = await checkSubscriptionStatus();
        setIsSubscribed(subscriptionStatus);

        let initialQuizId = paramQuizId ? Number(paramQuizId) : null;
        if (!initialQuizId) {
          initialQuizId = await initializeQuizForUser(subscriptionStatus);
        }
        setQuizId(initialQuizId);
      } catch (error) {
        console.error('Error initializing quiz:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsLoading(false);
      }
    };

    initQuiz();
  }, [paramQuizId]);

  if (isLoading) {
    <div className="new-home-container">
    <div className="new-home-content">
      <div className="new-home-inner">

      </div>
    </div>
  </div>
    return <div>Loading...</div>;
  }

  if (!quizId) {
    <div className="new-home-container">
    <div className="new-home-content">
      <div className="new-home-inner">

      </div>
    </div>
  </div>
    return <div>Error: Unable to initialize quiz</div>;
  }

  const QuizComponent = isSubscribed ? SubscriberQuiz : FreeQuiz;

  return (
    <div className="new-home-container flex items-center justify-center px-4 py-8">
        <QuizContainer quizId={quizId} isSubscribed={isSubscribed} QuizComponent={QuizComponent}/>
    </div>
  );
};

export default QuizApp;