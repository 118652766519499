import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { hasSavedQuizProgress, clearAllQuizData, hasCompletedTests, checkForDataToClear } from './utils/quizUtils';
import { FaSun, FaMoon, FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';
import useDarkMode from './hooks/useDarkMode';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';

const Header: React.FC = () => {
  const [hasUserProfile, setHasUserProfile] = useState(false);
  const [quizInProgress, setQuizInProgress] = useState(false);
  const [currentQuizId, setCurrentQuizId] = useState<string | null>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hasDataToClear, setHasDataToClear] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const navigate = useNavigate();

  const checkQuizStatus = useCallback(async () => {
    try {
      const { inProgress, quizId } = await hasSavedQuizProgress();
      const dataToClear = await checkForDataToClear();
      
      setIsLoggedIn(!!auth.currentUser);
      setQuizInProgress(inProgress);
      setCurrentQuizId(quizId);
      setHasDataToClear(dataToClear);
    } catch (error) {
      console.error('Error checking quiz status:', error);
    }
  }, []);

  useEffect(() => {
    checkQuizStatus();

    const handleQuizProgressUpdate = () => {
      checkQuizStatus();
    };

    const handleQuizCompleted = () => {
      setQuizInProgress(false);
      setCurrentQuizId(null);
    };

    const unsubscribe = auth.onAuthStateChanged(() => {
      checkQuizStatus();
    });

    window.addEventListener('quizProgressUpdated', handleQuizProgressUpdate);
    window.addEventListener('storage', checkQuizStatus);
    window.addEventListener('quizDataCleared', checkQuizStatus);
    window.addEventListener('quizCompleted', handleQuizCompleted);

    return () => {
      unsubscribe();
      window.removeEventListener('quizProgressUpdated', handleQuizProgressUpdate);
      window.removeEventListener('storage', checkQuizStatus);
      window.removeEventListener('quizDataCleared', checkQuizStatus);
      window.removeEventListener('quizCompleted', handleQuizCompleted);
    };
  }, [checkQuizStatus]);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleContinueQuiz = () => {
    if (currentQuizId) {
      navigate(`/quiz/${currentQuizId}`);
    } else {
      navigate('/');
    }
    closeMobileMenu();
  };

  const handleClearData = async () => {
    await clearAllQuizData();
    setHasUserProfile(false);
    setQuizInProgress(false);
    setCurrentQuizId(null);
    setHasDataToClear(false);
    alert('All quiz data has been cleared.');
  };

  const handleSignInOut = async () => {
    if (isLoggedIn) {
      try {
        await signOut(auth);
        navigate('/');
      } catch (error) {
        console.error('Error signing out:', error);
      }
    } else {
      navigate('/signin');
    }
  };

  return (
    <header className="header">
      <div className="nav-container">
          <div className="nav-logo">
            <Link to="/" onClick={closeMobileMenu} className="text-xl font-bold">
              Social Work Test Prep Academy
            </Link>
          </div>
          <nav className="hidden md:flex space-x-4">
            <Link to="/about" className="nav-link">About Us</Link>
            <Link to="/pricing" className="nav-link">Pricing</Link>
            {isLoggedIn && <Link to="/feedback" className="nav-link">Feedback</Link>}
            {isLoggedIn && <Link to="/profile" className="nav-link">Profile</Link>}
            {isLoggedIn && quizInProgress && (
              <button 
                onClick={handleContinueQuiz}
                className="continue-quiz-button"
              >
                Continue Quiz
              </button>
            )}
            {process.env.NODE_ENV === 'development' && hasDataToClear && (
              <button 
                onClick={handleClearData}
                className="clear-data-button"
              >
                Clear Data
              </button>
            )}
            <button
              onClick={toggleDarkMode}
              className={`dark-mode-toggle ${isDarkMode ? 'active' : ''}`}
              aria-label="Toggle dark mode"
            >
              <FaSun className="sun-icon" />
              <FaMoon className="moon-icon" />
            </button>
            <button
              onClick={handleSignInOut}
              className="sign-in-out-button"
            >
              {isLoggedIn ? (
                <>
                  <FaSignOutAlt className="mr-2" />
                  Sign Out
                </>
              ) : (
                <>
                  <FaSignInAlt className="mr-2" />
                  Sign In
                </>
              )}
            </button>
          </nav>
          <button 
            className={`md:hidden mobile-menu-button ${mobileMenuOpen ? 'active' : ''}`}
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
          </button>
      </div>
      <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
        <Link to="/about" className="mobile-menu-link" onClick={closeMobileMenu}>About Us</Link>
        <Link to="/pricing" className="mobile-menu-link" onClick={closeMobileMenu}>Pricing</Link>
        {isLoggedIn && <Link to="/feedback" className="mobile-menu-link" onClick={closeMobileMenu}>Feedback</Link>}
        {isLoggedIn && <Link to="/profile" className="mobile-menu-link" onClick={closeMobileMenu}>Profile</Link>}
        {isLoggedIn && quizInProgress && (
          <button 
            onClick={() => {
              handleContinueQuiz();
              closeMobileMenu();
            }}
            className="mobile-menu-link continue-quiz"
          >
            Continue Quiz
          </button>
        )}
        {process.env.NODE_ENV === 'development' && hasDataToClear && (
          <button 
            onClick={() => {
              handleClearData();
              closeMobileMenu();
            }}
            className="mobile-menu-link clear-data"
          >
            Clear Data
          </button>
        )}
        <button
          onClick={() => {
            toggleDarkMode();
            closeMobileMenu();
          }}
          className="mobile-menu-link flex items-center justify-between"
        >
          <span className={`dark-mode-toggle ${isDarkMode ? 'active' : ''} ml-2`}>
            <FaSun className="sun-icon" />
            <FaMoon className="moon-icon" />
          </span>
        </button>
        <button
          onClick={() => {
            handleSignInOut();
            closeMobileMenu();
          }}
          className="mobile-menu-link flex items-center justify-between"
        >
          {isLoggedIn ? (
            <>
              Sign Out
              <FaSignOutAlt className="ml-2" />
            </>
          ) : (
            <>
              Sign In
              <FaSignInAlt className="ml-2" />
            </>
          )}
        </button>
      </div>
    </header>
  );
};

export default Header;