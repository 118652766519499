import { Question } from './Quiz'

export interface User {
    id: string
    email: string
    profile: UserProfile | null;
    quizProgress: QuizProgress | null
    testResults: TestResult[]
    subscriptionStatus: string | ''
}

export interface UserProfile {
  name: string;
  examDate: string;
  studyGoal: string;
  educationLevel: EducationLevel;
  preferredStudyTime: PreferredStudyTime;
  challengingTopics: ChallengingTopic[];
  learningStyle: LearningStyle;
}

export interface QuizProgress {
    quizId: number;
    currentQuestionIndex: number;
    initialQuestionIndex: number;
    score: number;
    date: string;
    wrongAnswers: WrongAnswer[];
    questions: Question[]; // Keep this for backward compatibility
    questionIds: number[]; // Change this to number[]
}

// TODO: Implement analytics for test results
export interface TestResult {
    id: number;
    date: string;
    score: number;
    totalQuestions: number;
    percentage: number;
    questions: Question[];
    wrongAnswers: WrongAnswer[];
    timeTaken?: number; // in seconds
}

export interface WrongAnswer {
    question: Question;
    explanation: string;
    date: string;
}

export enum StudyGoal {
    PASS_EXAM = "Pass the LCSW exam",
    IMPROVE_SKILLS = "Improve clinical skills",
    CAREER_ADVANCEMENT = "Career advancement"
}

export enum EducationLevel {
    BACHELORS = "Bachelor's in Social Work",
    MASTERS = "Master's in Social Work",
    OTHER = "Other related degree"
}

export enum WorkExperience {
    LESS_THAN_2 = "Less than 2 years",
    TWO_TO_5 = "2-5 years",
    FIVE_TO_10 = "5-10 years",
    MORE_THAN_10 = "More than 10 years"
}

export enum PreferredStudyTime {
    MORNING = "Morning",
    AFTERNOON = "Afternoon",
    EVENING = "Evening",
    NIGHT = "Night"
}

export enum ChallengingTopic {
    ETHICS = "Ethics",
    DIAGNOSIS = "Diagnosis",
    TREATMENT_PLANNING = "Treatment Planning",
    HUMAN_DEVELOPMENT = "Human Development",
    PSYCHOTHERAPY = "Psychotherapy",
    DIVERSITY = "Diversity and Cultural Competence",
    RESEARCH = "Research and Program Evaluation"
}

export enum LearningStyle {
    VISUAL = "Visual",
    AUDITORY = "Auditory",
    READING_WRITING = "Reading/Writing",
    KINESTHETIC = "Kinesthetic"
}