import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Timer from './Timer';
import ProgressBar from './ProgressBar';
import { QuizProgress, TestResult, WrongAnswer } from '../models/User';
import { Question } from '../models/Quiz';
import { INITIAL_QUIZ_TIME, PASSING_PERCENTAGE } from '../constants';
import ErrorBoundary from './ErrorBoundary';
import LoadingSpinner from './LoadingSpinner';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from '../utils/Analytics';
import { saveQuizProgress, loadQuizProgress, saveTestResult, clearQuizProgress, sanitizeQuestion } from '../utils/quizUtils';
import { fetchQuestions } from '../utils/FirebaseUtils'

interface QuizContainerProps {
  quizId: number;
  isSubscribed: boolean;
  QuizComponent: React.ComponentType<any>;
}

const QuizContainer: React.FC<QuizContainerProps> = ({ quizId, isSubscribed, QuizComponent }) => {
  const navigate = useNavigate();
  const [quizProgress, setQuizProgress] = useState<QuizProgress | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [quizFinished, setQuizFinished] = useState(false);
  const [timeLeft, setTimeLeft] = useState(INITIAL_QUIZ_TIME);
  const [showHint, setShowHint] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [showResult, setShowResult] = useState(false);
  const [currentExplanation, setCurrentExplanation] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loadedQuestions, setLoadedQuestions] = useState<Question[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const hasTimedOutRef = useRef(false);
  const resultContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadQuiz = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const savedProgress = await loadQuizProgress(quizId, isSubscribed);
        if (savedProgress) {
          setQuizProgress(savedProgress);
          if (savedProgress.questions.length === 0 && savedProgress.questionIds.length > 0) {
            const fetchedQuestions = await fetchQuestions(isSubscribed, savedProgress.questionIds);
            if (fetchedQuestions.length === 0) {
              throw new Error("Failed to fetch any questions");
            }
            setLoadedQuestions(fetchedQuestions);
            const updatedProgress = { ...savedProgress, questions: fetchedQuestions };
            setQuizProgress(updatedProgress);
            await saveQuizProgress(updatedProgress.quizId, updatedProgress);
          } else {
            setLoadedQuestions(savedProgress.questions);
          }
        } else {
          throw new Error("Failed to load quiz progress");
        }
      } catch (err) {
        console.error("Error in loadQuiz:", err);
        setError(err instanceof Error ? err : new Error('Failed to load quiz'));
      } finally {
        setIsLoading(false);
      }
    };

    loadQuiz();
  }, [quizId]);

  const handleTimeUp = useCallback(() => {
    if (hasTimedOutRef.current) return;
    hasTimedOutRef.current = true;
    handleAnswer(null);
  }, []);

  useEffect(() => {
    if (timeLeft > 0 && !quizFinished) {
      timerRef.current = setTimeout(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
    } else if (timeLeft === 0 && !quizFinished) {
      handleTimeUp();
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [timeLeft, quizFinished, handleTimeUp]);

  const handleAnswer = useCallback((answer: string | null) => {
    if (!quizProgress) return;

    const currentQuestion = quizProgress.questions[quizProgress.currentQuestionIndex];
    const isCorrect = answer === currentQuestion.correct_answer;
    
    const newScore = isCorrect ? quizProgress.score + 1 : quizProgress.score;
    const updatedQuestions = quizProgress.questions.map((q, index) => 
      index === quizProgress.currentQuestionIndex ? {...q, selected_answer: answer} : q
    );

    const updatedProgress = {
      ...quizProgress,
      score: newScore,
      questions: updatedQuestions,
    };

    if (!isCorrect) {
      updatedProgress.wrongAnswers = [
        ...updatedProgress.wrongAnswers,
        {
          question: sanitizeQuestion({...currentQuestion, selected_answer: answer}),
          explanation: currentQuestion.explanation,
          date: new Date().toISOString()
        }
      ];
    }

    setQuizProgress(updatedProgress);
    saveQuizProgress(quizId, updatedProgress);
    setSelectedAnswer(answer);
    setShowResult(true);

    logAnalyticsEvent(ANALYTICS_EVENTS.QUESTION_ANSWERED, {
      questionId: currentQuestion.id,
      isCorrect: isCorrect
    });
  }, [quizProgress, quizId]);

  const nextQuestion = useCallback(() => {
    if (!quizProgress) return;

    if (quizProgress.currentQuestionIndex < quizProgress.questions.length - 1) {
      const newIndex = quizProgress.currentQuestionIndex + 1;
      
      const updatedProgress = {
        ...quizProgress,
        currentQuestionIndex: newIndex,
      };
      setQuizProgress(updatedProgress);
      saveQuizProgress(quizId, updatedProgress);

      setTimeLeft(INITIAL_QUIZ_TIME);
      hasTimedOutRef.current = false;
      setSelectedAnswer(null);
      setShowResult(false);
      setShowHint(false);
      setCurrentExplanation('');
    } else {
      handleQuizFinish();
    }
  }, [quizProgress, quizId]);

  const handleQuizFinish = useCallback(async () => {
    if (!quizProgress) return;

    setQuizFinished(true);

    const testResult: TestResult = {
      id: Date.now(),
      date: new Date().toISOString(),
      score: quizProgress.score,
      totalQuestions: quizProgress.questions.length,
      percentage: (quizProgress.score / quizProgress.questions.length) * 100,
      questions: quizProgress.questions,
      wrongAnswers: quizProgress.wrongAnswers
    };

    await saveTestResult(testResult);
    await clearQuizProgress(quizId);

    logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_COMPLETED, {
      score: quizProgress.score,
      totalQuestions: quizProgress.questions.length,
      percentage: testResult.percentage
    });

    window.dispatchEvent(new Event('quizCompleted'));
    window.dispatchEvent(new Event('storage'));
  }, [quizProgress, quizId]);

  const calculateProgress = () => {
    if (!quizProgress) return 0;
    return (quizProgress.currentQuestionIndex / quizProgress.questions.length) * 100;
  };

  const handleExplanationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentExplanation(e.target.value);
  };

  const handleSaveAndContinue = () => {
    if (currentExplanation === '') {
      setShowConfirmation(true);
    } else {
      saveExplanation();
    }
  };

  const saveExplanation = () => {
    if (!quizProgress) return;
    const currentQuestion = quizProgress.questions[quizProgress.currentQuestionIndex];
    const newWrongAnswer: WrongAnswer = {
      question: currentQuestion,
      explanation: currentExplanation,
      date: new Date().toISOString()
    };
    const updatedProgress = {
      ...quizProgress,
      wrongAnswers: [...quizProgress.wrongAnswers, newWrongAnswer]
    };
    setQuizProgress(updatedProgress);
    saveQuizProgress(quizId, updatedProgress);
    setCurrentExplanation('');
    nextQuestion();
  };

  const confirmContinueWithoutExplanation = () => {
    setShowConfirmation(false);
    nextQuestion();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="error-container">
        <h2>Error Loading Quiz</h2>
        <p>{error.message}</p>
        <button onClick={() => window.location.reload()}>Try Again</button>
      </div>
    );
  }

  if (!quizProgress || loadedQuestions.length === 0) {
    return (
      <div className="error-container">
        <h2>No Questions Available</h2>
        <p>We could not load any questions for this quiz. Please try again later.</p>
        <button onClick={() => window.location.href = '/'}>Return to Home</button>
      </div>
    );
  }

  if (quizFinished) {
    const percentage = (quizProgress.score / quizProgress.questions.length) * 100;
    const passed = percentage >= PASSING_PERCENTAGE;

    return (
        <div className="quiz-completion-container">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          {/* <p className="text-lg mb-2">Your score: {score} out of {totalQuestions}</p> */}
          <p className="text-lg mb-4">Percentage: {percentage.toFixed(2)}%</p>
          <h3 className={`text-xl font-semibold mb-2 ${passed ? 'text-green-600' : 'text-red-600'}`}>
            {passed ? "Congratulations! You passed!" : "Sorry, you didn't pass."}
          </h3>
          {!passed && <p className="mb-6 text-gray-600">Keep studying and try again!</p>}

          <button 
            className="w-full bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600 transition-colors duration-200 mb-4"
            onClick={() => navigate('/profile')}
          >
            Save Results and Go to Your Profile
          </button>
          <button 
            className="w-full bg-green-500 text-white px-6 py-3 rounded hover:bg-green-600 transition-colors duration-200"
            onClick={() => navigate('/feedback')}
          >
            Give Us Feedback
          </button>
        </div>
    );
  }

  const currentQuestion = quizProgress?.questions[quizProgress.currentQuestionIndex];

  return (
    <ErrorBoundary>
      <div className="quiz-container bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="quiz-content p-6 space-y-6">
          <div className="flex items-center justify-between">
            <Timer timeLeft={timeLeft} />
            <div className="w-2/3">
              <ProgressBar progress={calculateProgress()} />
            </div>
          </div>
          <hr className="mb-4 border-t border-gray-300" />
          <p className="text-sm text-gray-600 mb-2">
            {isSubscribed ? 'Full 170-question test' : '30-question free quiz'}
          </p>
          <QuizComponent
            quizProgress={quizProgress}
            handleAnswer={handleAnswer}
            nextQuestion={nextQuestion}
            showHint={showHint}
            setShowHint={setShowHint}
            selectedAnswer={selectedAnswer}
            showResult={showResult}
            currentExplanation={currentExplanation}
            handleExplanationChange={handleExplanationChange}
            handleSaveAndContinue={handleSaveAndContinue}
          />
          {showConfirmation && (
            <div className="confirmation-modal">
              <div className="confirmation-modal-content">
                <p>You have not provided an explanation. Are you sure you want to continue?</p>
                <div className="confirmation-modal-buttons">
                  <button onClick={confirmContinueWithoutExplanation}>Yes, continue</button>
                  <button onClick={() => setShowConfirmation(false)}>No, go back</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

{/* <div className="quiz-container">
<div className="quiz-content">
  {!quizFinished && (
    <>
      <div className="flex items-center space-x-4 mb-4">
        <Timer timeLeft={timeLeft} />
        <div className="flex-grow">
          <ProgressBar progress={calculateProgress()} />
        </div>
      </div>
      <hr className="mb-4 border-t border-gray-300" />
      <p className="text-sm text-gray-600 mb-2">
        {isSubscribed ? 'Full 170-question test' : '30-question free quiz'}
      </p>
    </>
  )}
  <p className="question_text">{currentQuestion?.question_text}</p>

  {currentQuestion?.hint && currentQuestion.hint !== "" && (
    <>
      <button className="hint-button" onClick={() => setShowHint(!showHint)}>
        {showHint ? 'Hide Hint' : 'Show Hint'}
      </button>
      {showHint && (
        <p className="hint-text">{currentQuestion.hint}</p>
      )}
    </>
  )}

  {currentQuestion && (
    <AnswerOptions
      currentQuestion={currentQuestion}
      selectedAnswer={selectedAnswer}
      showResult={showResult}
      handleAnswer={handleAnswer}
      isCorrect={(option => { return option == selectedAnswer})}
    />
  )}

  {showResult && (
    <div className="result-container" ref={resultContainerRef}>
      {selectedAnswer !== currentQuestion?.correct_answer ? (
        <>
          <div className="explanation-section">
            <p className="text-red-600 font-semibold mb-2">Incorrect. The correct answer is: {currentQuestion?.correct_answer}</p>
            <p className="mb-4"><strong>Explanation:</strong> {currentQuestion?.explanation}</p>
            <p className="font-bold text-blue-600 dark:text-blue-400">Please explain this concept in your own words:</p>
            <textarea
              value={currentExplanation}
              onChange={handleExplanationChange}
              placeholder="Enter your notes here..."
              rows={4}
              className="explanation-textarea"
            />
          </div>
          <div className="button-container">
            <button className="save-button" onClick={handleSaveAndContinue}>Save & Continue</button>
          </div>
        </>
      ) : (
        <>
          <p className="text-green-600 font-semibold mb-4">Correct!</p>
          <div className="button-container">
            <button className="next-button" onClick={nextQuestion}>
              {quizProgress && quizProgress.currentQuestionIndex < quizProgress.questions.length - 1 ? 'Continue' : 'Finish Quiz'}
            </button>
          </div>
        </>
      )}
    </div>
  )}

  {showConfirmation && (
    <div className="confirmation-modal">
      <div className="confirmation-modal-content">
        <p>You didnt add any notes to this question. Are you sure you want to continue?</p>
        <div className="confirmation-modal-buttons">
          <button onClick={confirmContinueWithoutExplanation}>Yes, continue</button>
          <button onClick={() => setShowConfirmation(false)}>No, go back</button>
        </div>
      </div>
    </div>
  )}
</div> */}
export default QuizContainer;