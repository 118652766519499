import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateUserProfile } from './utils/FirebaseUtils';
import { UserProfile, EducationLevel, PreferredStudyTime, ChallengingTopic, LearningStyle } from './models/User';
import LoadingSpinner from './components/LoadingSpinner';

const Onboarding: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile>({
    name: '',
    examDate: '',
    studyGoal: '',
    educationLevel: EducationLevel.BACHELORS,
    preferredStudyTime: PreferredStudyTime.MORNING,
    challengingTopics: [],
    learningStyle: LearningStyle.VISUAL
  });
  const [hasExamDate, setHasExamDate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleTopicChange = (topic: ChallengingTopic) => {
    setProfile(prev => ({
      ...prev,
      challengingTopics: prev.challengingTopics.includes(topic)
        ? prev.challengingTopics.filter(t => t !== topic)
        : [...prev.challengingTopics, topic]
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const profileToSubmit = {
        ...profile,
        examDate: hasExamDate ? profile.examDate : 'Not scheduled'
      };
      await updateUserProfile(profileToSubmit);
      setTimeout(() => {
        setIsLoading(false);
        navigate('/');
      }, 2000); 
    } catch (error) {
      console.error('Error creating user:', error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    <div className="new-home-container">
        <div className="new-home-content">
            <h2 className="text-2xl font-bold mb-4 text-center text-gray-800 dark:text-gray-200">Sign Up Now to Access Your Free AWSB LCSW Quiz!</h2>
                <div className="new-home-inner">
                    return <LoadingSpinner />;
                </div>
        </div>
    </div>
  }

  return (
    <div className="new-home-container">
        <div className="new-home-content">
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 flex items-center justify-center">
      <form onSubmit={handleSubmit} className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800 dark:text-gray-200">Create Your Profile</h2>
        
        <label className="block mb-2">
          Name:
          <input
            type="text"
            name="name"
            value={profile.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600"
          />
        </label>

        <div className="mb-2">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={hasExamDate}
              onChange={() => setHasExamDate(!hasExamDate)}
              className="mr-2"
            />
            I have an exam scheduled
          </label>
        </div>

        {hasExamDate && (
          <label className="block mb-2">
            Exam Date:
            <input
              type="date"
              name="examDate"
              value={profile.examDate}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600"
            />
          </label>
        )}

        <label className="block mb-2">
          Study Goal:
          <input
            type="text"
            name="studyGoal"
            value={profile.studyGoal}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600"
          />
        </label>

        <label className="block mb-2">
          Education Level:
          <select
            name="educationLevel"
            value={profile.educationLevel}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600"
          >
            {Object.values(EducationLevel).map(level => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </label>

        <label className="block mb-2">
          Preferred Study Time:
          <select
            name="preferredStudyTime"
            value={profile.preferredStudyTime}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600"
          >
            {Object.values(PreferredStudyTime).map(time => (
              <option key={time} value={time}>{time}</option>
            ))}
          </select>
        </label>

        <fieldset className="mb-2">
          <legend className="mb-1">Challenging Topics:</legend>
          {Object.values(ChallengingTopic).map(topic => (
            <label key={topic} className="flex items-center">
              <input
                type="checkbox"
                checked={profile.challengingTopics.includes(topic)}
                onChange={() => handleTopicChange(topic)}
                className="mr-2"
              />
              {topic}
            </label>
          ))}
        </fieldset>

        <label className="block mb-2">
          Learning Style:
          <select
            name="learningStyle"
            value={profile.learningStyle}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600"
          >
            {Object.values(LearningStyle).map(style => (
              <option key={style} value={style}>{style}</option>
            ))}
          </select>
        </label>

        <button type="submit" className="mt-4 w-full bg-blue-500 text-white p-2 rounded-md dark:bg-blue-700">
          Finish Your Profile
        </button>
      </form>
    </div>
    </div>
    </div>
  );
};

export default Onboarding;