import { db, auth } from '../firebase';
import { doc, setDoc, getDoc, updateDoc, collection, query, where, limit, startAfter, getDocs, documentId, orderBy, DocumentData, Query } from 'firebase/firestore';
import { QuizProgress, TestResult, WrongAnswer, UserProfile } from '../models/User';
import { Question } from '../models/Quiz';
import { sanitizeQuestion } from './quizUtils';
import { onSnapshot } from 'firebase/firestore';
import typedQuestionsData from './questions.json';

export const fetchQuestions = async (isSubscribed: boolean, questionIds?: number[], count = 10): Promise<Question[]> => {
  console.log(`Fetching questions. isSubscribed: ${isSubscribed}, questionIds: ${questionIds}, count: ${count}`);

  try {
    if (!isSubscribed) {
      console.log("User is not subscribed. Fetching local questions.");
      const localQuestions = typedQuestionsData.slice(0, count);
      console.log(`Fetched ${localQuestions.length} local questions`);
      return localQuestions.map(sanitizeQuestion);
    }

    console.log("User is subscribed. Fetching questions from Firestore.");
    const questionsRef = collection(db, 'questions');
    let q: Query<DocumentData>;

    if (questionIds && questionIds.length > 0) {
      console.log(`Fetching specific questions with IDs: ${questionIds.join(', ')}`);
      q = query(questionsRef, where(documentId(), 'in', questionIds.map(String)));
    } else {
      console.log(`Fetching questions`);
      q = query(questionsRef);
    }

    const querySnapshot = await getDocs(q);
    console.log(`Firestore query returned ${querySnapshot.docs.length} documents`);

    if (querySnapshot.empty) {
      console.warn("No questions found in Firestore");
      throw new Error("No questions found in the database");
    }

    const questions: Question[] = querySnapshot.docs.map(doc => {
      const questionData = doc.data() as Question;
      return sanitizeQuestion({
        ...questionData,
        id: doc.id
      });
    });

    console.log(`Successfully fetched and sanitized ${questions.length} questions`);
    return questions;

  } catch (error) {
    console.error('Error in fetchQuestions:', error);
    if (error instanceof Error) {
      console.error(`Error name: ${error.name}, message: ${error.message}`);
      if (error.stack) {
        console.error(`Stack trace: ${error.stack}`);
      }
    }
    throw new Error('Failed to fetch questions. Please try again later.');
  }
};

export const saveQuizProgress = async (quizId: string, progress: QuizProgress): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userDocRef = doc(db, 'users', user.uid);
  await setDoc(userDocRef, { 
    quizProgress: {
      ...progress,
      quizId,
      questionIds: progress.questions.map(q => q.id.toString()) // Add this line
    }
  }, { merge: true });
};

export const loadQuizProgress = async (quizId: string): Promise<QuizProgress | null> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userDocRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userDocRef);
  const data = userDoc.data();
  if (data?.quizProgress) {
    const progress = data.quizProgress as QuizProgress;
    if (!progress.questionIds) {
      progress.questionIds = progress.questions.map(q => q.id);
    }
    return progress;
  }
  return null;
};

export const saveTestResult = async (result: TestResult): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const sanitizedResult = {
    ...result,
    questions: result.questions.map(q => sanitizeQuestion(q)),
    wrongAnswers: result.wrongAnswers.map(wa => ({
      ...wa,
      question: sanitizeQuestion(wa.question)
    }))
  };

  const userDocRef = doc(db, 'users', user.uid);
  await updateDoc(userDocRef, {
    testResults: sanitizedResult
  });
};

export const loadTestResults = async (): Promise<TestResult[]> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userDocRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userDocRef);
  const data = userDoc.data();
  return data?.testResults || [];
};

export const saveWrongAnswers = async (wrongAnswers: WrongAnswer[]): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const sanitizedWrongAnswers = wrongAnswers.map(wa => ({
    ...wa,
    question: sanitizeQuestion(wa.question)
  }));

  const userDocRef = doc(db, 'users', user.uid);
  await updateDoc(userDocRef, {
    wrongAnswers: sanitizedWrongAnswers
  });
};

export const createUserInFirestore = async (uid: string, email: string) => {
  const userRef = doc(db, 'users', uid);
  await setDoc(userRef, {
    email: email,
    createdAt: new Date(),
  });
};

export const updateUserProfile = async (profile: UserProfile): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userDocRef = doc(db, 'users', user.uid);
  try {
    await updateDoc(userDocRef, {
      profile: profile
    });
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw new Error('Failed to update user profile');
  }
};

export const loadUserProfile = async (): Promise<UserProfile | null> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userDocRef = doc(db, 'users', user.uid);
  try {
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    return data?.profile || null;
  } catch (error) {
    console.error('Error loading user profile:', error);
    throw new Error('Failed to load user profile');
  }
};

export const checkSubscriptionStatus = async (): Promise<boolean> => {
  const user = auth.currentUser;
  if (!user) {
    console.log('No authenticated user in checkSubscriptionStatus');
    return false;
  }

  const userDocRef = doc(db, 'users', user.uid);
  try {
    console.log('Fetching user document for:', user.uid);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();
    
    if (!userData) {
      console.warn('User document not found for:', user.uid);
      return false;
    }
    console.log('User data:', userData);
    const isSubscribed = userData.subscriptionStatus === 'active';
    console.log('Is user subscribed:', isSubscribed);
    return isSubscribed;
  } catch (error) {
    console.error('Error checking subscription status:', error);
    return false;
  }
}

export const setupUserStatusListener = (
  userId: string, 
  onUpdate: (isSubscribed: boolean, testInProgress: string | null) => void
) => {
  const userDocRef = doc(db, 'users', userId);
  
  return onSnapshot(userDocRef, async (docSnapshot) => {
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      const isSubscribed = userData.subscriptionStatus === 'active';
      
      // Check for in-progress test
      const testInProgress = await checkSubscribedTestInProgress();
      
      onUpdate(isSubscribed, testInProgress);
    } else {
      onUpdate(false, null);
    }
  }, (error) => {
    console.error("Error listening to user status:", error);
  });
};

export const loadWrongAnswers = async (): Promise<WrongAnswer[]> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userDocRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userDocRef);
  const data = userDoc.data();
  return data?.wrongAnswers || [];

}

export const checkSubscribedTestInProgress = async (): Promise<string | null> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const testsRef = collection(db, 'subscribedTests');
  const q = query(
    testsRef,
    where('userId', '==', user.uid),
    where('status', '==', 'in-progress')
  );

  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      // Return the ID of the first in-progress test found
      return querySnapshot.docs[0].id;
    }
    return null;
  } catch (error) {
    console.error('Error checking for subscribed test in progress:', error);
    throw new Error('Failed to check for subscribed test in progress');
  }
};
