import { getAnalytics, logEvent } from 'firebase/analytics';
import { app } from '../firebase'; // Assuming you have your Firebase app initialization in this file

const analytics = getAnalytics(app);

// Analytics event names
export const ANALYTICS_EVENTS = {
  QUIZ_STARTED: 'quiz_started',
  QUIZ_COMPLETED: 'quiz_completed',
  QUESTION_ANSWERED: 'question_answered',
  PROFILE_VIEWED: 'profile_viewed',
  RETAKE_QUIZ: 'retake_quiz',
  CONTINUE_SAVED_QUIZ: 'continue_saved_quiz',
};

// Function to log events
export const logAnalyticsEvent = (eventName: string, eventParams?: object) => {
  logEvent(analytics, eventName, eventParams);
};